import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getInitials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return;
    let words = value.split(' ');
    let initials = words.map(word => word[0]).join('').toUpperCase();

    if (initials.length > 2) {
      initials = initials.substring(0, 2);
    }

    return initials;
  }

}