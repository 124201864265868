import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiService } from '../api/api.service';
import { skipWhile, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
    providedIn: 'root'
})
export class IpAddressService {
    private url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${environment.api_key}`;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private afAuth: AngularFireAuth
    ) { }

    getIp() {
        return this.http.get(this.url);
    }

    saveUserSession(payload: any) {
        return this.apiService.post(`users/user_sessions`, payload);
    }

    userSession(id: string, page: number, limit: number){
        const params = new HttpParams()
        .set('page', page.toString())
        .set('limit', limit.toString())
        .set('userID', id);
        return this.apiService.get(`users/${id}/user_sessions?${params.toString()}`);
    }

    async getUserIp() {
        this.getIp().pipe(skipWhile(val => !val), take(1)).subscribe((res: any) => {
            this.saveUserSessions(res?.ip_address, res?.country);
        });
    }

    saveUserSessions(ipAddress: number, country: string) {
        this.afAuth.currentUser.then(user => {
            if (user) {
                const payload = {
                    user: user.uid,
                    ip_address: ipAddress,
                    login_time: new Date(),
                    logout_time: new Date(),
                    location: country
                };
                this.saveUserSession(payload).pipe(skipWhile(val => !val), take(1)).subscribe((res) => {})
            } else {
                console.error('No user signed in.');
            }
        }).catch(error => {
            console.error('Error getting current user:', error);
        });
    }
}
