import { Action, State, StateContext } from "@ngxs/store";
import { AddUserStateModel, AuthStateModel } from "./auth.model";
import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api/api.service";
import { AddUser, AuthAction_SubscribeWebTokenToTopic, log_out_user } from "./auth.actions";
import { take, tap } from "rxjs";
import { AuthService } from "app/shared/services/auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

const defaults = {
    userById: undefined,
    loader: false,
    id: '',
    postLoader: false,
    user: undefined
};

@State<AddUserStateModel>({
    name: 'AddUser',
    defaults
})

@Injectable()
export class userState {
    constructor(
        private api: ApiService,
        private authService: AuthService,
        private _snackbar: MatSnackBar
    ) { }

    @Action(AddUser) AddUser(
        { patchState }: StateContext<AddUserStateModel>,

        { data, isModal }: AddUser
    ) {
        let url = "users"
        patchState({
            postLoader: true,
            userById: null
        })
        this.api.post(url, data).pipe(take(1)).subscribe({
            next: (res) => {
                patchState({ postLoader: false })
            },
            error: (e) => {
                patchState({ postLoader: false })
            }
        })
    }

    @Action(log_out_user) log_out_user(
        { patchState }: StateContext<AuthStateModel>
    ) {
        this.authService.signOut();
        patchState({ ...defaults });

    }

    @Action(AuthAction_SubscribeWebTokenToTopic)
    subscribeWebTokenToTopic({ patchState }: StateContext<AuthStateModel>, { token }: AuthAction_SubscribeWebTokenToTopic) {
        return this.authService.subscribeToTopic(token).pipe(
            tap({
                next: async (res) => {
                    console.log(res);
                },
                error: async (err) => {
                    this._snackbar.open('Error occured while subscribing Web Token To Topic!', 'Close', {
                        duration: 3000
                    });
                }
            })
        )
    }
}
