import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { states } from './state-list';
import { environment } from '../../../environments/environment';


@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot([...states] , { developmentMode: !environment.production}),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
  ],
})
export class StoreModule { }
