<!-- Navigation -->
<!-- <ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <ng-container fuseVerticalNavigationContentHeader>
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container> -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative bg-primary flex flex-0 items-center justify-between w-full h-18 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b dark:bg-transparent print:hidden">
        <ng-container>
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-36" src="assets/images/logo/logo.png">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation
                *ngIf="!isScreenSmall"
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
            <div class="h-10 w-10">
                <user [showAvatar]="true"></user>
            </div>
        </ng-container>
        <!-- Navigation toggle button -->
        <!-- <ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container> -->
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>
