import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Auth, User as _User } from '@angular/fire/auth';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { log_out_user } from 'app/shared/store/auth/auth.actions';
import { Subject, takeUntil } from 'rxjs';
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    @Input() clientDashboard: boolean = false;
    user: _User;
    userRole: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _auth: Auth,
        private _changeDetectorRef: ChangeDetectorRef,
        private _store: Store,
        private _userService: UserService,
        private _router: Router,
        private _ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private authService: AuthService
    ) {
        this.userRole = this.authService.userRole;
        this._auth.onAuthStateChanged((user: _User) => {
            if (!user) {
                this._ngZone.run(() => {
                    this._router.navigate(['sign-in'])
                })
            } else {
                this.user = user;
                this.cdr.detectChanges();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                // this.user = user;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // // Return if user is not available
        // if (!this.user) {
        //     return;
        // }

        // // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status,
        // }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._store.dispatch(log_out_user)
    }
}
