import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { User, UserStateModel } from './user.model';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddUser, FetchUser, FetchUserRole, UpdateUser, resetState } from './user.actions';
import { take, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@State<UserStateModel>({
    name: 'users',
    defaults: {
        user: null,
        userRole: null,
        loader: false
    }
})
@Injectable()
export class UserState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchUser)
    fetchUser({ patchState, setState }: StateContext<UserStateModel>,
        { id }: FetchUser) {
        patchState({ loader: true });
        this.apiService.get(`users/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<UserStateModel>({
                            user: patch<User>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<UserStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching User data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(AddUser)
    addUser({ patchState, setState }: StateContext<UserStateModel>,
        { payload }: AddUser
    ) {
        patchState({ loader: true });
        this.apiService.post('users', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<UserStateModel>({
                        user: patch<User>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('User created successfuly.', 'close', {
                    duration: 3000
                });
            },
            error: (e) => {
                setState(
                    patch<UserStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding User.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateUser)
    updateUser({ patchState, setState }: StateContext<UserStateModel>,
        { id, payload }: UpdateUser
    ) {
        patchState({ loader: true });
        this.apiService.patch(`users/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<UserStateModel>({
                        user: patch<User>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('User updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchUser(id))
            },
            error: (e) => {
                setState(
                    patch<UserStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating User data.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetState)
    resetState({ setState }: StateContext<UserStateModel>
    ) {
        setState(
            patch<UserStateModel>({
                user: null,
                loader: false
            })
        )
    }

    @Action(FetchUserRole)
    fetchStoreTypes({ patchState, setState }: StateContext<UserStateModel>,
    ) {
        patchState({ loader: false });
        this.apiService.get(`users/user_roles`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<UserStateModel>({
                            userRole: res,
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<UserStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching User data', 'close', {
                    duration: 3000
                });
            }
        })
    }

}
