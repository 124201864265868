import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { patch, append, removeItem} from "@ngxs/store/operators";
import { toastClose, toastShow } from "./toast.actions";
import { toast, ToastStateModel } from "./toast.model";
import * as uuid from 'uuid';

const defaults = {
    toasts: []
};

@State<ToastStateModel>({
  name: 'Toasts',
  defaults
})

@Injectable()
export class ToastState {

  constructor() { }

  @Action(toastShow) showToast(
    { dispatch, setState }: StateContext<ToastStateModel>,
    { toast }: toastShow
  ){
    toast.id = uuid.v4();
    setState(
      patch<ToastStateModel>({
        toasts: append<toast>([toast])
      })
    );

    if(toast.duration !== 0){
      setTimeout(() => {
        if(toast.id)
          dispatch(new toastClose(toast.id));
      }, toast.duration);
    }
  }

  @Action(toastClose) closeToast (
    { setState }: StateContext<ToastStateModel>,
    { id }: toastClose
  ){
    setState(
      patch<ToastStateModel>({
        toasts: removeItem<toast>(t => t?.id === id)
      })
    );
  }

}
