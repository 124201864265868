import { toast } from "./toast.model";

export class toastShow {
    static readonly type = '[toast] Show a toast notification';
    constructor(public toast: toast){}
}

export class toastClose {
    static readonly type = '[toast] Hide an active toast notification';
    constructor(public id: string){}
}
