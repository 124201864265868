import { NgIf } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { AvatarComponent } from 'app/shared/components/avatar/avatar.component';
import { LocalStorageService } from 'app/shared/services/localStorage/local-storage.service';
import { Subject, takeUntil } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { InitialsPipeModule } from 'app/shared/pipes/initials/initials.module';
import { io } from 'socket.io-client';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { IMessage } from 'app/shared/store/chat/chat.models';

const socket = io(environment.api.baseUrl);

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, FuseVerticalNavigationComponent, NotificationsComponent, UserComponent, NgIf, MatIconModule, MatButtonModule, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet, QuickChatComponent, AvatarComponent, InitialsPipeModule],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    userName: string;
    userEmail: string;
    userImage: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('audio') audio!: ElementRef;

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _localStorage: LocalStorageService,
        private afAuth: AngularFireAuth,
        private toastrService: ToastrService,
        private router: Router,
        private ngZone: NgZone
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        this.getUserAndEmail();
        this.getFirebaseUserImage();

        socket.on('onMessage', (data: { message: IMessage, contact: string }) => {
            if (this.audio.nativeElement) (this.audio.nativeElement as HTMLAudioElement).play();
            this.toastrService.info(data.message.body, data.contact).onTap.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                this.ngZone.run(() => this.router.navigate(['/communication/' + data.message.chatId]))
            })
        })
    }

    getUserAndEmail() {
        let userInfo = this._localStorage.getUser();
        this.userName = userInfo?.name;
        this.userEmail = userInfo?.email;
    }

    getFirebaseUserImage() {
        this.afAuth.authState.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
            if (user) {
                this.userImage = user.multiFactor.user.photoURL;
            }
        });
    }

    getInitials(firstAndLastName: string) {
        const words = firstAndLastName.trim().split(/\s+/);

        if (words.length === 0) {
            return '';
        }

        const firstInitial = words[0][0].toUpperCase();
        const lastInitial = words[words.length - 1][0].toUpperCase();

        return firstInitial + lastInitial;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
