import { EmitSearchListingState, ListingState, ShowCustomizeListingState } from './listing/listing.states';
import { ToastState } from './toast/toast.state';
import { LoaderState } from './loader/loader.states';
import { VendorState } from './vendor/vendor.states';
import { userState } from './auth/auth.states';
import { ContactState } from './contact/contact.states';
import { GooglePlaceState } from './googlePlace/googlePlace.states';
import { StoreState } from './store/store.states';
import { AssetState } from './asset/asset-form/asset-form.states';
import { AssetDetailState } from './asset/asset-detail/asset-detail.states';
import { ClientState } from './client/client.state';
import { InstallerState } from './installer/installer.states';
import { UserState } from './user/user.state';
import { CampaignAssetsAndStoresState, CampaignState } from './campaign/campaign.state';
import { ChatsState } from './chat/chat.state';

export const states = [
    EmitSearchListingState,
    ListingState,
    ShowCustomizeListingState,
    ToastState,
    LoaderState,
    VendorState,
    userState,
    ContactState,
    GooglePlaceState,
    StoreState,
    AssetState,
    AssetDetailState,
    ClientState,
    InstallerState,
    UserState,
    CampaignState,
    CampaignAssetsAndStoresState,
    ChatsState
]
