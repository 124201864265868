<!-- Button -->
<button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-10 h-10 rounded-full border-2 border-[#5B45DD] border-separate border-spacing-2"
            *ngIf="user?.photoURL && (userRole === 'client_admin' || userRole === 'client_user'); else showIcon"
            [src]="user?.photoURL">
        <ng-template #showIcon>
            <mat-icon
                [ngClass]="{'text-white': clientDashboard}"
                [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </ng-template> 
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
