import { Client } from "./client.model";

export class AddClient {
    static readonly type = '[Client] Add Client';
    constructor(public payload: Client) {}
}

export class UpdateClient {
    static readonly type = '[Client] Update Client';
    constructor(public id: string, public payload: Client) { }
}

export class FetchClient {
    static readonly type = '[Client] Fetch Client';
    constructor(public id: string) { }
}

export class resetState {
    static readonly type = '[Client] Reset Client';
    constructor() { }
}
