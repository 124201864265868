import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { toastClose, toastShow } from '../../store/toast/toast.actions';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private _store: Store
    ) { }

    showToast(type: 'success' | 'warning' | 'danger', title: string, duration: number, message?: string) {
        this._store.dispatch(new toastShow({
            title: title,
            type: type,
            message: message,
            duration: duration
        }));
    }

    closeToast(id: string) {
        this._store.dispatch(new toastClose(id));
    }
}
