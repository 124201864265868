import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { inject } from '@angular/core';
import { from, map, switchMap, of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleRedirectResolver implements Resolve<void> {
  private auth = inject(Auth);

  constructor(private router: Router) {}

  resolve() {
    return authState(this.auth).pipe(
      switchMap(user => {
        if (user) {
          return from(user.getIdTokenResult()).pipe(
            map(idTokenResult => {
              const claims = idTokenResult.claims;
              let redirectPath = 'sign-in'; // Default redirection
              if (claims.role === 'client_admin' || claims.role === 'client_user') {
                redirectPath = 'map-view';
              } else if (claims.role === 'client_designer') {
                redirectPath = 'production/artwork?subStatus=Signed%20%26%20Awaiting%20Client%20Artwor';
              } else if (claims.role === 'installer') {
                redirectPath = 'production/installer-jobs?subStatus=in-progress';
              } else if (claims.role === 'admin' || claims.role === 'store_manager') {
                redirectPath = 'store';
              } else if (claims.role === 'communication_manager'){
                redirectPath = 'communication'
              }
              this.router.navigate([redirectPath]);
            })
          );
        } else {  
          this.router.navigate(['sign-in']);
          return of();
        }
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private auth: Auth, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const allowedRoles = route.data.allowedRoles;
    return authState(this.auth).pipe(
      switchMap(user => {
        if (user) {
          return from(user.getIdTokenResult()).pipe(
            map(idTokenResult => {
              const claims = idTokenResult.claims;
              if (allowedRoles.includes(claims.role)) {
                return true;
              } else {
                this.router.navigate(['']); // Redirect to an unauthorized page or any other page
                return false;
              }
            })
          );
        } else {
          this.router.navigate(['']); // Redirect to sign-in page if not authenticated
          return of(false);
        }
      })
    );
  }
}
