import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Contact, ContactStateModel } from './contact.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddContact, UpdateContact, resetState } from './contact.actions';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FetchVendor } from '../vendor/vendor.actions';
import { FetchStore } from '../store/store.actions';
import { FetchClient } from '../client/client.actions';
import { FetchInstaller } from '../installer/installer.actions';

@State<ContactStateModel>({
    name: 'contacts',
    defaults: {
        contact: null,
        loader: false
    }
})
@Injectable()
export class ContactState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(AddContact)
    addVendor({ patchState, setState }: StateContext<ContactStateModel>,
        { payload, module }: AddContact
    ) {
        patchState({ loader: true });
        this.apiService.post('contacts', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<ContactStateModel>({
                        contact: patch<Contact>(res),
                        loader: false
                    })
                )
                if (module === 'vendor') {
                    this.store.dispatch(new FetchVendor(payload?.vendor))
                } else if (module === 'store') {
                    this.store.dispatch(new FetchStore(payload?.store))
                } else if (module === 'installer') {
                    this.store.dispatch(new FetchInstaller(payload?.installer))
                } else if (module === 'client') {
                    this.store.dispatch(new FetchClient(payload?.client))
                }
            },
            error: (e) => {
                setState(
                    patch<ContactStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding contact.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateContact)
    updateVendor({ patchState, setState }: StateContext<ContactStateModel>,
        { id, payload, module }: UpdateContact
    ) {
        patchState({ loader: true });
        this.apiService.patch(`contacts/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<ContactStateModel>({
                        contact: patch<Contact>(res),
                        loader: false
                    })
                )
                this._snackBar.open('Contact updated successfuly', 'close', {
                    duration: 3000
                });
                if (module === 'vendor') {
                    this.store.dispatch(new FetchVendor(payload?.vendor))
                } else if (module === 'store') {
                    this.store.dispatch(new FetchStore(payload?.store))
                } else if (module === 'client') {
                    this.store.dispatch(new FetchClient(payload?.client))
                } else if (module === 'installer') {
                    this.store.dispatch(new FetchInstaller(payload?.installer))
                }
            },
            error: (e) => {
                setState(
                    patch<ContactStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating contact.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetState)
    resetState({ setState }: StateContext<ContactStateModel>
    ) {
        setState(
            patch<ContactStateModel>({
                contact: null,
                loader: false
            })
        )
    }

}
