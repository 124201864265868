import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AssetStateModel } from './asset-form.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddAsset, UpdateAsset, resetAsset, getAssetById } from './asset-form.actions';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@State<AssetStateModel>({
    name: 'assets',
    defaults: {
        asset: null,
        loader: false
    }
})
@Injectable()
export class AssetState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store,
        private route: ActivatedRoute,
        public _router: Router,
    ) { }

    @Action(AddAsset)
    addVendor({ patchState, setState }: StateContext<AssetStateModel>,
        { payload, id }: AddAsset
    ) {
        patchState({ loader: true });
        this.apiService.post('assets', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<AssetStateModel>({
                        asset: patch<any>(res),
                        loader: false
                    })
                )
                this._snackBar.open('Asset created successfuly', 'close', {
                    duration: 3000
                });
                this._router.navigate([`/store/detail/${id}`], {
                    queryParams: { 
                        activatedTab: 'assets' 
                    }
                })

            },
            error: (e) => {
                setState(
                    patch<AssetStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding Asset.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateAsset)
    updateVendor({ patchState, setState }: StateContext<AssetStateModel>,
        { id, payload }: UpdateAsset
    ) {
        patchState({ loader: true });
        this.apiService.patch(`assets/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<AssetStateModel>({
                        asset: patch<any>(res),
                        loader: false
                    })
                )
                this._snackBar.open('Asset Updated successfuly', 'close', {
                    duration: 3000
                });
                this._router.navigate([`/store/detail/${id}`], {
                    queryParams: { 
                        activatedTab: 'assets' 
                    }
                })
            },
            error: (e) => {
                setState(
                    patch<AssetStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating Asset.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(getAssetById)
    fetchVendor({ patchState, setState }: StateContext<AssetStateModel>,
        { id, skipAuth, user, clientId, assetId, campaignId }: getAssetById) {
        patchState({ loader: true });
        
        let headers = undefined;
        if(skipAuth){
            headers = {
                'skipAuth': skipAuth.toString()
            }
        }

        let url = `stores/${id}/assetsDetails`

        if (clientId && user) url += `?user=${user}&clientId=${clientId}`
        else {
            if (user) url += `?user=${user}`
            if (clientId) url += `?clientId=${clientId}`
        }

        if (assetId && url.includes('?')) url += `&assetId=${assetId}`
        else if (assetId && !url.includes('?')) url += `?assetId=${assetId}`

        if (campaignId && url.includes('?')) url += `&campaignId=${campaignId}`
        else if (campaignId && !url.includes('?')) url += `?campaignId=${campaignId}`


        this.apiService.get(url, null, headers).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<AssetStateModel>({
                            asset: patch<any>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<AssetStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching Assets data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(resetAsset)
    resetState({ setState }: StateContext<AssetStateModel>
    ) {
        setState(
            patch<AssetStateModel>({
                asset: null,
                loader: false
            })
        )
    }

}
