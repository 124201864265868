
import moment from "moment"
import { IMessage } from "./chat.models"

export class Chat_GetChats {
    static readonly type = '[Chat] Get Chats'
    constructor(public page: number, public limit: number, public showLoader: boolean = true, public search?: string, public shouldAppend?: boolean, public status?: 'all' | 'unread'){}
}

export class Chat_RefreshChats {
    static readonly type = '[Chat] Refresh Chats'
    constructor(){}
}

export class Chat_MarkChatAsRead {
    static readonly type = '[Chat] Mark Chat as Read'
    constructor(public chatId: string) {}
}

export class Chat_MarkChatAsUnread {
    static readonly type = '[Chat] Mark Chat as Unread'
    constructor(public chatId: string) {}
}

export class Chat_GetChatMessages {
    static readonly type = '[Chat] Get Chat Messages'
    constructor(public chatId: string, public limit: number, public cursor?: string, public showLoader: boolean = true){}
}

export class Chat_SendMessage {
    static readonly type = '[Chat] Send Message'
    constructor(public message: IMessage, public schedule?: moment.Moment, public readableSchedule?: string){}
}

export class Chat_ComposeMessage {
    static readonly type = '[Chat] Compose Message'
    constructor(public body: string, public recipients: string[], public media?: string[], public schedule?: moment.Moment, public readableSchedule?: string){}
}

export class Chat_ReceiveMessage {
    static readonly type = '[Chat] Message Received'
    constructor(public message: IMessage){}
}

export class Chat_ResetChats {
    static readonly type = '[Chat] Reset Chats'
    constructor(){}
}

export class Chat_ResetConversation {
    static readonly type = '[Chat] Reset Conversation'
    constructor(){}
}

export class Chat_UpdateMessageStatus {
    static readonly type = '[Chat] Update Message Status'
    constructor(public message: IMessage){}
}

export class Chat_SearchStoreContacts {
    static readonly type = '[Chat] Search Store Contacts'
    constructor(public search: string){}
}

export class Chat_SearchInstallerContacts {
    static readonly type = '[Chat] Search Installer Contacts'
    constructor(public search: string){}
}

export class Chat_ResetCompose {
    static readonly type = '[Chat] Reset Compose'
}

export class Chat_GetTemplates {
    static readonly type = '[Chat] Get all templates'
    constructor(public page: number, public search?: string){}
}

export class Chat_CreateTemplate {
    static readonly type = '[Chat] Create a template'
    constructor(public title: string, public body: string, public showMiniLoader?: boolean){}
}

export class Chat_UpdateTemplate {
    static readonly type = '[Chat] Update a template'
    constructor(public id: string, public title: string, public body: string){}
}

export class Chat_DeleteTemplate {
    static readonly type = '[Chat] Delete a template'
    constructor(public id: string){}
}

export class Chat_ResetTemplates {
    static readonly type = '[Chat] Reset Templates'
}

export class Chat_DownloadMedia {
    static readonly type = '[Chat] Download Media'
    constructor(public url: string){}
}