import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Vendor, VendorStateModel } from './vendor.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddVendor, FetchVendor, UpdateVendor, resetState } from './vendor.actions';
import { take, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@State<VendorStateModel>({
    name: 'vendors',
    defaults: {
        vendor: null,
        loader: false
    }
})
@Injectable()
export class VendorState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchVendor)
    fetchVendor({ patchState, setState }: StateContext<VendorStateModel>,
        { id }: FetchVendor) {
        patchState({ loader: true });
        this.apiService.get(`vendors/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<VendorStateModel>({
                            vendor: patch<Vendor>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<VendorStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching vendor data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(AddVendor)
    addVendor({ patchState, setState }: StateContext<VendorStateModel>,
        { payload }: AddVendor
    ) {
        patchState({ loader: true });
        this.apiService.post('vendors', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<VendorStateModel>({
                        vendor: patch<Vendor>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Vendor created successfuly.', 'close', {
                    duration: 3000
                });
            },
            error: (e) => {
                setState(
                    patch<VendorStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding vendor.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateVendor)
    updateVendor({ patchState, setState }: StateContext<VendorStateModel>,
        { id, payload }: UpdateVendor
    ) {
        patchState({ loader: true });
        this.apiService.patch(`vendors/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<VendorStateModel>({
                        vendor: patch<Vendor>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Vendor updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchVendor(id))
            },
            error: (e) => {
                setState(
                    patch<VendorStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating vendor data.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetState)
    resetState({ setState }: StateContext<VendorStateModel>
    ) {
        setState(
            patch<VendorStateModel>({
                vendor: null,
                loader: false
            })
        )
    }

}
