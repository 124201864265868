import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InstallerStateModel } from './installer.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { FetchInstaller, UpdateInstaller, resetInstaller } from './installer.actions';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@State<InstallerStateModel>({
    name: 'installers',
    defaults: {
        installer: null,
        loader: false
    }
})
@Injectable()
export class InstallerState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }


    @Action(FetchInstaller)
    fetchStore({ patchState, setState }: StateContext<InstallerStateModel>,
        { id }: FetchInstaller) {
        patchState({ loader: true });
        this.apiService.get(`installers/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<InstallerStateModel>({
                            installer: patch<any>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<InstallerStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching Installer data', 'close', {
                    duration: 3000
                });
            }
        })
    }


    @Action(UpdateInstaller)
    updateVendor({ patchState, setState }: StateContext<InstallerStateModel>,
        { id, payload }: UpdateInstaller
    ) {
        patchState({ loader: true });
        this.apiService.patch(`vendors/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<InstallerStateModel>({
                        installer: patch<any>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Installer updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchInstaller(id))
            },
            error: (e) => {
                setState(
                    patch<InstallerStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating installer data.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetInstaller)
    resetState({ setState }: StateContext<InstallerStateModel>
    ) {
        setState(
            patch<InstallerStateModel>({
                installer: null,
                loader: false
            })
        )
    }

}
