import { Contact } from "./contact.models";

export class AddContact {
    static readonly type = '[Contact] Add Contact';
    constructor(public payload: Contact, public module: string) { }
}

export class UpdateContact {
    static readonly type = '[Contact] Update Contact';
    constructor(public id: string, public payload: Contact, public module: string) { }
}

export class resetState {
    static readonly type = '[Contact] Reset Contact';
    constructor() { }
}