import { Action, State, StateContext, Store } from "@ngxs/store";
import { CreateAddress, GooglePlaceStateModel } from "./googlePlace.model";
import { Injectable } from "@angular/core";
import { ApiService } from "app/shared/services/api/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FetchGooglePlace } from "./googlePlace.actions";
import { take } from "rxjs";
import { patch } from "@ngxs/store/operators";
import { HeaderParams } from "../listing/listing.model";



@State<GooglePlaceStateModel>({
    name: 'googlePlace',
    defaults: {
        googlePlace: null,
        loader: false
    }
})
@Injectable()
export class GooglePlaceState {
    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchGooglePlace)
    fetchStore({ patchState, setState }: StateContext<GooglePlaceStateModel>,
        { search }: FetchGooglePlace
    ) {
        const params: HeaderParams = {};
        patchState({ loader: true });
        if(search){
            params['search'] = search;
        }
        this.apiService.get(`places`, params).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<GooglePlaceStateModel>({
                            googlePlace: res,
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<GooglePlaceStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching google place data', 'close', {
                    duration: 3000
                });
            }
        })
    }
}
