import { Route, Router } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';

import { LayoutComponent } from 'app/layout/layout.component';

import { AuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { RoleGuard, RoleRedirectResolver } from './shared/app.resolver';

@Component({
    selector: 'app-dummy',
    template: ''
  })
export class DummyRouteComponent {}

const redirectLoggedInToDashboard = () => redirectLoggedInTo('');
const redirectUnauthorizedToSignin = () => redirectUnauthorizedTo('/sign-in');

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path based on user role
    {
        path: '',
        pathMatch: 'full',
        component: DummyRouteComponent,
        resolve: {
            redirect: RoleRedirectResolver
        }
    },

    // Separate Routing for contract page to allow auth or unauth access
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'vendor/:id/contract/:id', loadChildren: () => import('app/shared/components/general-terms-condition/general-terms-condition.routes') },
            { path: 'proposal-review/:id', loadChildren: () => import('app/modules/admin/client-proposal-review/client-proposal-review.routes') },
            { path: 'store/:id/contract/:id', loadChildren: () => import('app/shared/components/insertion-order/insertion-order.route') },
            { path: 'qbo/success', loadChildren: () => import('app/modules/auth/qbo-success-redirect/qbo-success-redirect.routes') },
            { path: 'vendor/:id/w9/:id', loadChildren: () => import('app/shared/components/w9-form/w9-form.route') },
        ]
    },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
            authGuardPipe: redirectLoggedInToDashboard
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
        ]
    },


    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        data: {
            authGuardPipe: redirectUnauthorizedToSignin
        },
        children: [
            // { path: 'vendor', loadChildren: () => import('app/modules/admin/vendor/vendor.routes') },

            { 
                path: 'store', 
                loadChildren: () => import('app/modules/admin/store/store.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager']
                }
            },

            { 
                path: 'communication', 
                loadChildren: () => import('app/modules/admin/chat/chat.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager', 'communication_manager']
                }
            },
            { 
                path: 'proposals', 
                loadChildren: () => import('app/modules/admin/proposals/proposals.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin']
                }
            },
            { 
                path: 'campaigns', 
                loadChildren: () => import('app/modules/admin/campaigns/campaigns.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager', 'client_admin', 'client_user']
                }
            },
            { 
                path: 'map', 
                loadChildren: () => import('app/modules/admin/map-view/map-view.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager']
                }
            },
            { 
                path: 'map-view', 
                loadChildren: () => import('app/modules/admin/client-map-view/client-map-view.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['client_user', 'client_admin']
                }
            },
            { 
                path: 'client', 
                loadChildren: () => import('app/modules/admin/client/client.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager']
                }
            },
            { 
                path: 'installer', 
                loadChildren: () => import('app/modules/admin/installer/installer.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager']
                }
            },
            { 
                path: 'user', 
                loadChildren: () => import('app/modules/admin/user/user.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'client_admin']
                }
            },
            { 
                path: 'analytics', 
                loadChildren: () => import('app/modules/admin/analytics/analytics.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['client_user', 'client_admin']
                }
            },
            { 
                path: 'production', 
                loadChildren: () => import('app/modules/admin/production/production.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager', 'client_user', 'client_admin', 'client_designer', 'installer', 'prepress']
                }
            },
            { 
                path: 'products', 
                loadChildren: () => import('app/modules/admin/products/products.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager']
                }
            },
            { 
                path: 'account-payable', 
                loadChildren: () => import('app/modules/admin/account-payable/account-payable.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager', 'accounting_manager']
                }
            },
            { 
                path: 'agreements', 
                loadChildren: () => import('app/modules/admin/client-dashboard/client-agreement/client-agreement.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin', 'store_manager', 'client_admin']
                }
            },
            { 
                path: 'email-notification', 
                loadChildren: () => import('app/modules/admin/EmailNotification/email-notifications.routes'),
                canActivate: [RoleGuard],
                data: {
                    allowedRoles: ['admin']
                }
            },
            // { path: 'account-receivable', loadChildren: () => import('app/modules/admin/account-receivable/account-receivable.routes') },
            // { path: 'account-payable', loadChildren: () => import('app/modules/admin/account-payable/account-payable.routes') },
            { path: 'under-development', loadChildren: () => import('app/modules/admin/under-development/under-development.routes') },
            { path: '**', redirectTo: '/sign-in' }
        ]
    },
];

// dummy.component.ts
import { Component } from '@angular/core';