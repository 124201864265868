import { Injectable } from '@angular/core';
import { firebaseUser, user, UserDetails } from '../../store/auth/auth.model'
import { LocalStorageRefService } from './loval-storage-ref.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private localStorage: Storage;
    constructor(
        private _localStorageRefService: LocalStorageRefService
    ) {
        this.localStorage = _localStorageRefService.localStorage;
    }

    // -------------------------------------------------------------------------
    getUnknown(unknownKey: string) {
        const data = JSON.parse(this.localStorage.getItem(unknownKey)!);
        return data;
    }

    getUser(): UserDetails {
        const user: UserDetails = JSON.parse(this.localStorage.getItem('user')!);
        return user;
    }

    getToken(): string {
        return this.localStorage.getItem('token');
    }

    getRefreshToken(): string {
        return this.localStorage.getItem('refresh_token');
    }

    // -------------------------------------------------------------------------

    setUser(user: UserDetails): void {
        const setUser = JSON.stringify(user);
        this.localStorage.setItem('user', setUser);
    }

    setToken(token: string): void {
        this.localStorage.setItem('token', token);
    }

    setRefreshToken(token: string): void {
        this.localStorage.setItem('refresh_token', token);
    }

    // -------------------------------------------------------------------------

    clearUser(): void {
        this.localStorage.removeItem('user');
        this.localStorage.removeItem('token');
        this.localStorage.removeItem('refresh_token');
    }

    sessionExpire(): void {
        this.localStorage.removeItem('user');
        this.localStorage.removeItem('token');
        this.localStorage.removeItem('refresh_token');
    }
}
