import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AssetDetailStateModel } from './asset-detail.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { getAssets, resetAsset } from './asset-detail.actions';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@State<AssetDetailStateModel>({
    name: 'assetsDetail',
    defaults: {
        asset: null,
        loader: false
    }
})
@Injectable()
export class AssetDetailState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
    ) { }


    @Action(getAssets)
    getAssets({ patchState, setState }: StateContext<AssetDetailStateModel>,
        { id }: getAssets) {
        patchState({ loader: true });
        this.apiService.get(`stores/${id}/assetsDetails`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<AssetDetailStateModel>({
                            asset: patch<any>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<AssetDetailStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching Asset data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(resetAsset)
    resetState({ setState }: StateContext<AssetDetailStateModel>
    ) {
        setState(
            patch<AssetDetailStateModel>({
                asset: null,
                loader: false
            })
        )
    }

}
