import { Vendor } from "./vendor.models";

export class AddVendor {
    static readonly type = '[Vendor] Add Vendor';
    constructor(public payload: Vendor) {}
}

export class UpdateVendor {
    static readonly type = '[Vendor] Update Vendor';
    constructor(public id: string, public payload: Vendor) { }
}

export class FetchVendor {
    static readonly type = '[Vendor] Fetch Vendor';
    constructor(public id: string) { }
}

export class resetState {
    static readonly type = '[Vendor] Reset Vendor';
    constructor() { }
}