import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ShowLoader, ShowPageLoader, ShowPostLoader } from './loader.actions';
import { LoaderStateModel } from "./loader.models";



const defaults = {
  loader: false,
  postLoader: false,
  pageLoader:false
};


@State<LoaderStateModel>({
  name: 'ShowLoader',
  defaults
})

@Injectable()
export class LoaderState {

  constructor() { }


  @Action(ShowLoader)
  ShowLoader(
    { patchState }: StateContext<LoaderStateModel>,
    { loader }: ShowLoader) {
    patchState({ loader: loader });
  }

  @Action(ShowPostLoader)
  ShowPostLoaderLoader(
    { patchState }: StateContext<LoaderStateModel>,
    { postLoader }: ShowPostLoader) {
    patchState({ postLoader: postLoader });
  }



  @Action(ShowPageLoader) ShowPageLoader({ patchState }: StateContext<LoaderStateModel>, { pageLoader }: ShowPageLoader) {
    patchState({ pageLoader: pageLoader });
  }

}
