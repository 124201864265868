<fuse-vertical-navigation class="dark bg-primary-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <div class="flex items-center justify-center">
                <img class="w-8" src="assets/images/logo/logo-mark-main.png">
            </div>
            <div class="flex items-center ml-auto">
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full object-cover" *ngIf="userImage" [src]="userImage"
                    alt="User avatar">
                <div *ngIf="!userImage" class="h-24 w-24 rounded-full bg-gray-300 flex justify-center items-center">
                    <span class="text-lg md:text-2xl text-gray-700 font-bold">{{ userName |
                        getInitials }}</span>
                </div>
                <mat-icon class="icon-size-24" *ngIf="user?.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{userName}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{userEmail}}
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
        </div>
    </div> -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true">
            <audio #audio src="../../../../../assets/files/notification_sound.mp3"></audio>
        </router-outlet>
    </div>
</div>