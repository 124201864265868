export class findAll {
    static readonly type = "[listing] Get All Records";
    constructor(
        public module: string,
        public page: number,
        public limit: number,
        public filter: any,
        public append_data: boolean,
        public search?: any,
        public sortOrder?: string[],
        public routeId?: string,
        public moduleName?: string,
        public moduleData?: { module: string, id: string },
        public lazyLoad?: boolean,
        public vendorId?: string,
    ) { 
    }
}

export class showCustomizePanel {
    static readonly type = "[listing] Show Customize Panel";
    constructor(public show: boolean) { }
}

export class setListingStateNull {
    static readonly type = "[listing] Set Listing Null";
    constructor() { }
}

export class updateColumnsVisibility {
    static readonly type = "[listing] Update Visibility of Columns";
    constructor(public columns: any[]) { }
}

export class emitSearch {
    static readonly type = "[listing] Emit Search Update";
    constructor(public search: string) { }
}

export class SelectedCheckBox {
    static readonly type = '[SelectCheckBox] Select Check Box';
    constructor(public data: any, public selected: boolean) {
    }
}
