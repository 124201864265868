import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, skipWhile, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request should skip authentication
        if (request.headers.get('skipAuth') === 'true') {
            // Clone the request without the 'skipAuth' header before forwarding it
            const newRequest = request.clone({
                headers: request.headers.delete('skipAuth')
            });
            return next.handle(newRequest);
        }

        // Existing token logic for authenticated requests
        return this._authService.authToken$.pipe(
            skipWhile(val => val?.length === 0),
            take(1),
            switchMap(token => {
                const newRequest = token ? this.addToken(request, token) : request;
                return next.handle(newRequest).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            return this.handle401Error(request, next);
                        } else {
                            return throwError(error);
                        }
                    })
                );
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this._authService.refreshToken().pipe(
            switchMap((token: string) => {
                const newRequest = this.addToken(request, token);
                return next.handle(newRequest);
            }),
            catchError((refreshError) => {
                this._authService.signOut();
                return throwError(refreshError);
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return request;
    }
}
