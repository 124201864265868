export const environment = {
    production: false,
    api: {
        baseUrl: 'https://dev-api.onstore.com/',
        //baseUrl: 'http://localhost:3000/',
    },
    firebaseConfig: {
        apiKey: "AIzaSyAbbhiADKNlfWAj14nST4Ot7RWJFwYWrKU",
        authDomain: "onstore-dev-f691f.firebaseapp.com",
        projectId: "onstore-dev-f691f",
        storageBucket: "onstore-dev-f691f.appspot.com",
        messagingSenderId: "211350335672",
        appId: "1:211350335672:web:d432323429b0f84602844d",
        measurementId: "G-KXGGH1GPBE",
        vapidKey: "BKqsveGg2Zo-1-MbPeOZR_qg4agvKdw86LgrOhTWktVobZ72-dRCN2O7Orx9SAKSfEKtDWA3Exg4m8zf6VAr8mg"
    },
    cloudinary: {
        cloudName: 'dbqufebj4',
        apiKey: '139137765558177',
        apiSecret: 'hsOg-ldjplTC5oOpwT_Pdig1Fgg'
    },
    google: {
        places: {
            baseUrl: 'https://maps.googleapis.com/maps/api/place/',
            fromTextSearch: 'findplacefromtext/json?fields=formatted_address%2Cname&inputtype=textquery',
            apiKey: 'AIzaSyCrCKho0NqKUCuO5PeTw-w7azAQWqIdLyA'
        },
        map: {
            id: 'e2457477056dfdac'
        }
    },
    cloudinaryUrl: "https://api.cloudinary.com/v1_1",
    googleMapAPIKey:"AIzaSyCrCKho0NqKUCuO5PeTw-w7azAQWqIdLyA",
    api_key: '87c1c967b2aa47cc9befdbee44522023',
    s3_bucket: {
        bucketName: 'onstore-dev-artworks',
        AWS_REGION: 'us-west-1'
    },
    hmac: {
        secretKey: '092013377e97446f4ef0cba125dbed538288fd86e09799bf2672888901fe5631'
    },
    artwork: {
        templateZipUrl: 'https://onstore-dev-artworks.s3.us-west-1.amazonaws.com/public-assets/AI+Templates.zip',
        instructionPdfUrl: 'https://onstore-dev-artworks.s3.us-west-1.amazonaws.com/public-assets/Artwork+Submission+Requirements.pdf',
        banner: 'https://onstore-client-artwork-templates.s3.amazonaws.com/01+Banner+300x60.zip',
        landscape: 'https://onstore-client-artwork-templates.s3.amazonaws.com/04+Landscape+108x72.zip',
        square: 'https://onstore-client-artwork-templates.s3.amazonaws.com/03+Square+96x96.zip',
        portrait: 'https://onstore-client-artwork-templates.s3.amazonaws.com/02+Portrait+48in+x+120in.zip'
    }
}
