import { Campaign, IStoreAssets } from "./campaign.model";

export class AddCampaign {
    static readonly type = '[Campaign] Add Campaign';
    constructor(public payload: Campaign) { }
}

export class UpdateCampaign {
    static readonly type = '[Campaign] Update Campaign';
    constructor(public id: string, public payload: Campaign) { }
}

export class FetchCampaign {
    static readonly type = '[Campaign] Fetch Campaign';
    constructor(public id: string) { }
}

export class FetchCampaignStoresAndAssets {
    static readonly type = '[Campaign] Fetch Campaign Stores & Assets';
    constructor(public id: string) { }
}

export class AddStoresAssets {
    static readonly type = '[Campaign] Add Stores & Assets';
    constructor(public campaignId: string, public storesAssets: IStoreAssets) { }
}

export class FetchPrepressJobs {
    static readonly type = '[Campaign] Fetch Prepress Jobs';
    constructor(public campaignId: string, public all: boolean = false) { }
}

export class FetchInsertionOrders {
    static readonly type = '[Campaign] Fetch Insertion Orders';
    constructor(public campaignId: string) { }
}

export class resetCampaign {
    static readonly type = '[Campaign] Reset Campaign';
    constructor() { }
}

export class ResetStoresAndAssets {
    static readonly type = '[Campaign] Reset Stores And Assets';
    constructor() { }
}