import { cloneDeep } from 'lodash';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChatsStateModel, IChatRes, IChatsRes, IMessage, ITemplateRes } from './chat.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { Chat_ComposeMessage, Chat_CreateTemplate, Chat_DeleteTemplate, Chat_DownloadMedia, Chat_GetChatMessages, Chat_GetChats, Chat_GetTemplates, Chat_MarkAsAssign, Chat_MarkChatAsRead, Chat_MarkChatAsUnread, Chat_ReceiveMessage, Chat_RefreshChats, Chat_ResetChats, Chat_ResetCompose, Chat_ResetConversation, Chat_ResetTemplates, Chat_SearchInstallerContacts, Chat_SearchStoreContacts, Chat_SendMessage, Chat_UpdateMessageStatus, Chat_UpdateTemplate } from './chat.actions';
import { scheduled, take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import moment from 'moment-timezone';

@State<ChatsStateModel>({
    name: 'chats',
    defaults: {
        chats: [],
        page: 1,
        status: '',
        flag: '',
        chatsMeta: null,
        chatsLoader: false,
        isInitialLoaded: false,
        chat: [],
        chatMeta: null,
        chatLoader: false,
        selectedChat: null,
        messagesSendingFromCompose: false,
        storeContacts: [],
        isStoreLoading: false,
        installerContacts: [],
        isInstallerLoading: false,
        templates: [],
        templatesMeta: null,
        templatesPage: 1,
        templatesSearch: null,
        isTemplateLoading: true,
        templateMiniLoader: false,
        isTemplateCreating: false,
        isTemplateUpdating: false,
        isTemplateDeleting: false,

        isMediaDownloading: false
    }
})
@Injectable()
export class ChatsState {

    constructor(
        private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private http: HttpClient,
        private store: Store
    ) { }


    @Action(Chat_GetChats)
    getChats({ patchState, setState, getState }: StateContext<ChatsStateModel>, { page, limit, showLoader, search, shouldAppend, status, flag }: Chat_GetChats) {
        if (showLoader) patchState({ chatsLoader: true });
        let endpoint = `communication/chats?page=${page}&limit=${limit}`;
        if (status) endpoint += `&status=${status}`;
        if (flag) endpoint += `&flag=${flag}`;
        if (search) endpoint += `&search=${search}`;

        patchState({ status })
        patchState({ flag })
        this.apiService.get(endpoint).pipe(take(1)).subscribe({
            next: (res: IChatsRes) => {
                if (res) {
                    const _chats = cloneDeep(getState()?.chats);
                    patchState({ chats: shouldAppend ? [..._chats, ...res.data] : res.data, chatsMeta: res.meta, chatsLoader: false, page, isInitialLoaded: true })
                }
            },
            error: (e) => {
                setState(
                    patch<ChatsStateModel>({
                        chatsLoader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching chats data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_RefreshChats)
    refreshChats({ patchState, setState, getState }: StateContext<ChatsStateModel>) {
        this.apiService.get(`communication/chats?page=1&limit=${getState()?.page * 20}&status=${getState()?.status}`).pipe(take(1)).subscribe({
            next: (res: IChatsRes) => {
                if (res) {
                    patchState({ chats: res.data, chatsMeta: res.meta })
                }
            },
            error: (e) => {
                setState(
                    patch<ChatsStateModel>({
                        chatsLoader: false
                    })
                )
                this._snackBar.open('Error Occured while refreshing chats data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_MarkChatAsRead)
    markChatsAsRead({ patchState, getState }: StateContext<ChatsStateModel>, { chatId }: Chat_MarkChatAsRead) {
        const _chats = cloneDeep(getState()?.chats);
        const index = _chats.findIndex((chat) => chat.id == chatId)
        if (index > -1) _chats[index].isUnread = false
        patchState({ chats: _chats })
    }

    @Action(Chat_MarkChatAsUnread)
    Chat_MarkChatAsUnread({ patchState, getState }: StateContext<ChatsStateModel>, { chatId }: Chat_MarkChatAsUnread) {
        const _chats = cloneDeep(getState()?.chats);
        const index = _chats.findIndex((chat) => chat.id == chatId)
        if (index > -1) _chats[index].isUnread = true
        patchState({ chats: _chats })
    }

    @Action(Chat_GetChatMessages)
    getChatMessages({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { chatId, limit, cursor, showLoader }: Chat_GetChatMessages) {
        const _state = getState()?.chats.filter((chat) => chat.id === chatId);
        patchState({ chatLoader: !cursor && showLoader ? true : false, selectedChat: _state[0] });
        if (showLoader) patchState({ chatLoader: true, chat: [] })

        let endpoint = `communication/messages/${chatId}?limit=${limit}`;
        if (cursor) endpoint += `&cursor=${cursor}`;
        this.apiService.get(endpoint).pipe(take(1)).subscribe({
            next: (res: IChatRes) => {
                if (res) {
                    dispatch(new Chat_RefreshChats())
                    const currentMessages = getState()?.chat
                    patchState({ chat: cursor ? [...res.messages, ...currentMessages] : res.messages, chatMeta: res.meta, chatLoader: false })
                }
            },
            error: (e) => {
                setState(
                    patch<ChatsStateModel>({
                        chatLoader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching chat data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_SendMessage)
    sendMessage({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { message, schedule, readableSchedule }: Chat_SendMessage) {
        const phone = getState()?.selectedChat.phoneNumber
        message.phoneNumber = phone;
        patchState({ chat: [...getState()?.chat, message] })
        const _body = { message }
        if (schedule) { 
            _body['schedule'] = schedule.toISOString(); 
            _body['readableSchedule'] = readableSchedule; 
        }
        this.apiService.post(`communication/send-a-message/`, _body ).pipe(take(1)).subscribe({
            next: (res: IMessage) => {
                if (res) {
                    const currentMessages = cloneDeep(getState()?.chat);
                    const _i = currentMessages.findIndex((msg) => msg._id === message._id)
                    if (_i) {
                        currentMessages[_i] = res
                        const currentChatMeta = cloneDeep(getState()?.chatMeta)
                        currentChatMeta.total += 1;
                        patchState({ chat: [...currentMessages] })
                    }
                    dispatch(new Chat_RefreshChats())
                }
            },
            error: (e) => {
                setState(
                    patch<ChatsStateModel>({
                        chatLoader: false
                    })
                )
                this._snackBar.open('Error Occured while sending message.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_ComposeMessage)
    sendMessageFromCompose({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { body, recipients, media, schedule, readableSchedule }: Chat_ComposeMessage) {
        patchState({ messagesSendingFromCompose: true })
        const messageBody = { body, recipients }
        if (media) messageBody['media'] = media

        if (schedule) { 
            messageBody['schedule'] = schedule.toISOString(); 
            messageBody['readableSchedule'] = readableSchedule; 
        }
        this.apiService.post(`communication/send-message/`, messageBody).pipe(take(1)).subscribe({
            next: (res: IMessage) => {
                if (res) {
                    patchState({ messagesSendingFromCompose: false })
                    dispatch(new Chat_RefreshChats())
                    const _selectedChat = getState()?.selectedChat
                    const _FilteredSelectedChat = recipients.filter((recipient) => recipient === _selectedChat.phoneNumber)
                    if (_FilteredSelectedChat.length > 0) dispatch(new Chat_GetChatMessages(_selectedChat.id, 50, null, false))
                }
            },
            error: (e) => {
                patchState({ messagesSendingFromCompose: false })
                this._snackBar.open('Error Occured while sending message from Compose.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_ReceiveMessage)
    handleReceiveMessage({ patchState, getState, dispatch }: StateContext<ChatsStateModel>, { message }: Chat_ReceiveMessage) {
        const _chats = cloneDeep(getState()?.chats);
        const filteredChatIndex = _chats.findIndex((chat) => chat.id === message.chatId)
        if (filteredChatIndex > -1) {

            const newTimestamp = new Date().toISOString();
            message.createdAt = newTimestamp;
            const currentMessages = cloneDeep(getState()?.chat);
            currentMessages.push(message)
            const currentChatMeta = cloneDeep(getState()?.chatMeta)
            currentChatMeta.total += 1;

            const _selectedChat = cloneDeep(getState()?.selectedChat)

            _chats[filteredChatIndex].updatedAt = newTimestamp;
    
            if (_selectedChat.id === message.chatId) {
                _selectedChat.isUnread = false;
                _selectedChat.updatedAt = newTimestamp;
    
                _chats[filteredChatIndex].isUnread = false;
            } else _chats[filteredChatIndex].isUnread = true;
    
            _chats.sort((a,b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf());
            patchState({ chats: _chats, selectedChat: _selectedChat, chat: currentMessages, chatMeta: currentChatMeta })
        } else dispatch(new Chat_RefreshChats())

    }

    @Action(Chat_ResetChats)
    resetChats({ patchState }: StateContext<ChatsStateModel>) {
        patchState({ chats: [], page: 1, chatsMeta: null, chatsLoader: false, isInitialLoaded: false })
    }

    @Action(Chat_ResetConversation)
    resetConversation({ patchState }: StateContext<ChatsStateModel>) {
        patchState({ chat: [], selectedChat: null, chatMeta: null, chatLoader: false })
    }

    @Action(Chat_UpdateMessageStatus)
    updateMessageStatus({ patchState, getState }: StateContext<ChatsStateModel>, { message }: Chat_UpdateMessageStatus) {
        const _chat = cloneDeep(getState()?.chat);
        const index = _chat.findIndex((chat) => chat._id == message._id)
        _chat[index].status = message.status
        patchState({ chat: _chat })
    }

    @Action(Chat_SearchStoreContacts)
    searchStoreContacts({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { search }: Chat_SearchStoreContacts) {
        patchState({ isStoreLoading: true })
        this.apiService.get(`communication/search-store?search=${search}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    patchState({ storeContacts: res, isStoreLoading: false })
                }
            },
            error: (e) => {
                patchState({ isStoreLoading: false })
                this._snackBar.open('Error Occured while fetching stores.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_SearchInstallerContacts)
    searchInstallerContacts({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { search }: Chat_SearchInstallerContacts) {
        patchState({ isInstallerLoading: true })
        this.apiService.get(`communication/search-installer?search=${search}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    patchState({ installerContacts: res, isInstallerLoading: false })
                }
            },
            error: (e) => {
                patchState({ isInstallerLoading: false })
                this._snackBar.open('Error Occured while fetching installers.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_ResetCompose)
    resetCompose({ patchState }: StateContext<ChatsStateModel>) {
        patchState({ storeContacts: [], installerContacts: [] })
    }

    @Action(Chat_GetTemplates)
    getAllTemplates({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { page, search }: Chat_GetTemplates) {
        patchState({ templatesPage: page, templatesSearch: search })
        if (search) patchState({ templateMiniLoader: true })
        let url = `communication/templates?page=${page}&limit=20`
        if (search) url += `&search=${search}`
        this.apiService.get(url).pipe(take(1)).subscribe({
            next: (res: ITemplateRes) => {
                if (res) {
                    patchState({ templates: page === 1 ? res.templates : [...getState()?.templates, ...res.templates], templatesMeta: res.meta, isTemplateLoading: false, templateMiniLoader: false })
                }
            },
            error: (e) => {
                patchState({ isTemplateLoading: false })
                this._snackBar.open('Error Occured while fetching templates.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_CreateTemplate)
    createTemplate({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { title, body, showMiniLoader }: Chat_CreateTemplate) {
        patchState({ isTemplateCreating: true })
        if (showMiniLoader) patchState({ templateMiniLoader: true })
        this.apiService.post(`communication/template`, { title, body }).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    patchState({ isTemplateCreating: false })
                    dispatch(new Chat_GetTemplates(1))
                    // TODO::PUSH CREATED TEMPLATE IN TEMPLATES STATE
                    // patchState({ templates: res, isTemplateLoading: false })
                }
            },
            error: (e) => {
                patchState({ isTemplateCreating: false })
                this._snackBar.open('Error Occured while fetching templates.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_UpdateTemplate)
    updateTemplate({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { id, title, body }: Chat_UpdateTemplate) {
        patchState({ isTemplateUpdating: true })
        this.apiService.patch(`communication/template`, { id, title, body }).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    patchState({ isTemplateUpdating: false, templateMiniLoader: true })
                    dispatch(new Chat_GetTemplates(1))
                    // TODO::PUSH CREATED TEMPLATE IN TEMPLATES STATE
                    // patchState({ templates: res, isTemplateLoading: false })
                }
            },
            error: (e) => {
                patchState({ isTemplateUpdating: false })
                this._snackBar.open('Error Occured while fetching templates.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_DeleteTemplate)
    deleteTemplate({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { id }: Chat_DeleteTemplate) {
        patchState({ isTemplateDeleting: true, templateMiniLoader: true })
        this.apiService.delete(`communication/template/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    patchState({ isTemplateDeleting: false })
                    dispatch(new Chat_GetTemplates(1))
                    // TODO::PUSH CREATED TEMPLATE IN TEMPLATES STATE
                    // patchState({ templates: res })
                }
            },
            error: (e) => {
                patchState({ isTemplateDeleting: false })
                this._snackBar.open('Error Occured while fetching templates.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_ResetTemplates)
    resetTemplates({ patchState }: StateContext<ChatsStateModel>) {
        patchState({ templates: [], templatesMeta: null, templatesPage: 1, templatesSearch: null, templateMiniLoader: false, isTemplateLoading: true})
    }

    @Action(Chat_DownloadMedia)
    downloadMedia({ patchState, setState, getState, dispatch }: StateContext<ChatsStateModel>, { url }: Chat_DownloadMedia) {
        patchState({ isMediaDownloading: true })
        const headers = {
            'Content-Type': 'application/json',
        };
        this.http.get(environment.api.baseUrl + `communication/download-media/${encodeURIComponent(url)}`, { headers: new HttpHeaders(headers), responseType: 'blob' })
        .pipe(take(1)).subscribe({
            next: (res: Blob) => {
                if (res) {
                    const blob = new Blob([res], { type: 'image/jpg' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Onstore_Communication_Media_${moment.tz(moment.tz.guess()).format('YYYY-MM-DD__HH_mm_ss')}.jpeg`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    patchState({ isMediaDownloading: false })
                }
            },
            error: (e) => {
                patchState({ isMediaDownloading: false })
                this._snackBar.open('Error Occured while downloading image.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(Chat_MarkAsAssign)
    markAssign(
        { patchState }: StateContext<ChatsStateModel>,
        { flag, chatId, searchStr, currentTab, saveFlag }: Chat_MarkAsAssign
    ) {
        this.apiService
            .patch(`communication/flag/${chatId}/update`, flag)
            .pipe(take(1))
            .subscribe({
                next: (res: any) => {
                    if (res) {
                       this.store.dispatch(new Chat_GetChats(1, 20, true, searchStr, false, currentTab, saveFlag))
                    }
                },
                error: (e) => {
                    patchState({
                        chatLoader: false,
                    });
                    this._snackBar.open('Error occurred while marking chat as assigned.', 'close', {
                        duration: 3000
                    });
                },
            });
    }
}
