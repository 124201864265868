import { user } from './auth.model';

export class AddUser {
    static readonly type = '[User] AddUser';
    constructor(public data: any, public isModal: boolean) { }
}

export class log_in_user {
    static readonly type = '[auth] Log in user';
    constructor(public user: user){}
}

export class log_out_user {
    static readonly type = '[auth] Log out user';
    constructor(){}
}

export class AuthAction_SubscribeWebTokenToTopic {
    static readonly type = '[auth] Subscribe Web Token to Topic';
    constructor(public token: string){}
}
