export class UpdateInstaller {
    static readonly type = '[Installer] Update Installer';
    constructor(public id: string, public payload: any) { }
}

export class FetchInstaller {
    static readonly type = '[Installer] Fetch Installer';
    constructor(public id: string) { }
}

export class resetInstaller {
    static readonly type = '[Installer] Reset Installer';
    constructor() { }
}