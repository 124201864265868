import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { register as registerSwiperElements } from 'swiper/element/bundle';

(window as any).global = window;

registerSwiperElements();
bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
