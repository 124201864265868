
export class ShowLoader {
    static readonly type = '[Loader] ShowLoader';
    constructor(public loader: boolean) { }
}


export class ShowPostLoader {
    static readonly type = '[Loader] ShowLoader';
    constructor(public postLoader: boolean) { }
}


export class ShowPageLoader {
    static readonly type = '[Loader] page Loader';
    constructor(public pageLoader: boolean) { }
}
