import { Stores } from "./store.models";

export class AddStore {
    static readonly type = '[Store] Add Store';
    constructor(public payload: Stores) { }
}

export class UpdateStore {
    static readonly type = '[Store] Update Store';
    constructor(public id: string, public payload: Stores) { }
}

export class FetchStore {
    static readonly type = '[Store] Fetch Store';
    constructor(public id: string) { }
}

export class FetchStoreTypes {
    static readonly type = '[Store] Fetch Store Types';
    constructor() { }
}

export class resetStore {
    static readonly type = '[Store] Reset Store';
    constructor() { }
}
