import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { append, patch } from "@ngxs/store/operators";
import { Subscription, take } from "rxjs";
import { ApiService } from "../../services/api/api.service";
import { ToastService } from "../../services/toast/toast.service";
import { SelectedCheckBox, emitSearch, findAll, setListingStateNull, showCustomizePanel, updateColumnsVisibility } from "./listing.actions";
import { EmitSearchListingStateModel, GenericListing, HeaderParams, ListingStateModel, SettlementStateModel, ShowCustomizeListingStateModel } from "./listing.model";
import { ShowLoader } from "../loader/loader.actions";

const defaults = {
    listing: {
        data: [],
        meta: undefined,
        columns: []
    },
    loader: false
}
const defaultsCustomize = {
    show: false
}
@State<ListingStateModel>({
    name: 'genericListing',
    defaults
})
@Injectable()
export class ListingState {
    subscription: Subscription;
    constructor(
        private _api: ApiService,
        private _toast: ToastService,
        private _store: Store
    ) { }

    @Action(findAll) findAll(
        { patchState, setState }: StateContext<ListingStateModel>,
        { module, page, limit, filter, append_data, search, sortOrder, routeId, lazyLoad, moduleName, moduleData, vendorId }: findAll
    ) {
        if (!append_data && !lazyLoad) {
            patchState(defaults);
        }
        this._store.dispatch(new ShowLoader(false))
        patchState({ loader: true });
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        const params: HeaderParams = {};
        params['page'] = page;
        params['limit'] = limit;
        if (routeId) {
            params['routeId'] = routeId;
        }
        // if (moduleName) {
        //     params['moduleName'] = moduleName;
        // }
        if (typeof search === 'object') {
            params[search.column] = search.value;
        } else if (search) {
            params['search'] = search
        }
        if (sortOrder?.length > 0) {
            params['orderby'] = sortOrder[0];
            params['orderSequence'] = sortOrder[1];
        } if (moduleData) {
            params[moduleData?.module] = moduleData?.id
        }

        if (typeof filter === 'object') {
            if (filter.validationStatus) {
                params['validationStatus'] = filter.validationStatus
            } if (filter.visited !== undefined && filter.visited !== null ) {
                params['visited'] = filter.visited;
            } if (filter.grade) {
                params['grade'] = filter.grade;
            }
        }

        if (vendorId) {
            params['vendorId'] = vendorId;
        }
        this.subscription = this._api.get(module, params).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    if (append_data && lazyLoad) {
                        setState(
                            patch<ListingStateModel>({
                                listing: patch<GenericListing>({
                                    data: append(res?.data),
                                    meta: res?.meta,
                                    columns: res?.columns
                                }),
                                loader: false
                            })
                        )
                    } else {
                        setState(
                            patch<ListingStateModel>({
                                listing: patch<GenericListing>({
                                    data: res?.data,
                                    columns: res?.columns,
                                    meta: res?.meta,
                                }),

                                loader: false
                            })
                        )
                    }
                }
            },
            error: (e) => {
                this._toast.showToast('danger', 'Error', 3000, e.message);
                if (!append_data) {
                    setState(
                        patch<ListingStateModel>({
                            loader: false
                        })
                    )
                }
            }
        })
    }

    @Action(updateColumnsVisibility) updateColumnsVisibility(
        { setState }: StateContext<ListingStateModel>,
        { columns }: updateColumnsVisibility
    ) {

        setState(
            patch<ListingStateModel>({
                listing: patch<GenericListing>({
                    columns: columns
                })
            })
        );
    }

    @Action(setListingStateNull) setListingNull(
        { patchState }: StateContext<ListingStateModel>
    ) {
        patchState(defaults)
    }

    @Action(SelectedCheckBox)
    SelectedCheckBox(
        { patchState, setState }: StateContext<SettlementStateModel>,
        { data, selected }: SelectedCheckBox
    ) {
        patchState({ selectedCheckBox: data, selected: selected })
    }
}

@State<ShowCustomizeListingStateModel>({
    name: 'showCustomizeListing'
})
@Injectable()
export class ShowCustomizeListingState {

    constructor() { }

    @Action(showCustomizePanel) showCustomizePanel(
        { setState }: StateContext<ShowCustomizeListingStateModel>,
        { show }: showCustomizePanel
    ) {
        setState({
            show: show
        });
    }
}

@State<EmitSearchListingStateModel>({
    name: 'emitSearchListing'
})
@Injectable()
export class EmitSearchListingState {

    constructor() { }

    @Action(emitSearch) emitSearch(
        { setState }: StateContext<EmitSearchListingStateModel>,
        { search }: emitSearch
    ) {
        setState({
            search: search
        });
    }
}
