import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Client, ClientStateModel } from './client.model';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddClient, FetchClient, UpdateClient, resetState } from './client.actions';
import { take, tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@State<ClientStateModel>({
    name: 'clients',
    defaults: {
        client: null,
        loader: false
    }
})
@Injectable()
export class ClientState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchClient)
    fetchClient({ patchState, setState }: StateContext<ClientStateModel>,
        { id }: FetchClient) {
        patchState({ loader: true });
        this.apiService.get(`clients/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<ClientStateModel>({
                            client: patch<Client>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<ClientStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching client data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(AddClient)
    addClient({ patchState, setState }: StateContext<ClientStateModel>,
        { payload }: AddClient
    ) {
        patchState({ loader: true });
        this.apiService.post('clients', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<ClientStateModel>({
                        client: patch<Client>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Client created successfuly.', 'close', {
                    duration: 3000
                });
            },
            error: (e) => {
                setState(
                    patch<ClientStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding client.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateClient)
    updateClient({ patchState, setState }: StateContext<ClientStateModel>,
        { id, payload }: UpdateClient
    ) {
        patchState({ loader: true });
        this.apiService.patch(`clients/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<ClientStateModel>({
                        client: patch<Client>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Client updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchClient(id))
            },
            error: (e) => {
                setState(
                    patch<ClientStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating client data.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetState)
    resetState({ setState }: StateContext<ClientStateModel>
    ) {
        setState(
            patch<ClientStateModel>({
                client: null,
                loader: false
            })
        )
    }

}
