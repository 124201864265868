import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Stores, StoreStateModel } from './store.models';
import { ApiService } from 'app/shared/services/api/api.service';
import { AddStore, FetchStore, FetchStoreTypes, UpdateStore, resetStore } from './store.actions';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderParams } from '../listing/listing.model';

@State<StoreStateModel>({
    name: 'stores',
    defaults: {
        store: null,
        storeTypes: null,
        loader: false
    }
})
@Injectable()
export class StoreState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchStore)
    fetchStore({ patchState, setState }: StateContext<StoreStateModel>,
        { id }: FetchStore) {
        patchState({ loader: true, store: null });
        this.apiService.get(`stores/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<StoreStateModel>({
                            store: patch<Stores>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<StoreStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching store data', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(AddStore)
    addStore({ patchState, setState }: StateContext<StoreStateModel>,
        { payload }: AddStore
    ) {
        patchState({ loader: true });
        this.apiService.post('stores', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<StoreStateModel>({
                        store: patch<Stores>(res?.data),
                        loader: false
                    })
                )
            },
            error: (e) => {
                setState(
                    patch<StoreStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding store data', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateStore)
    updateStore({ patchState, setState }: StateContext<StoreStateModel>,
        { id, payload }: UpdateStore
    ) {
        patchState({ loader: true });
        this.apiService.patch(`stores/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<StoreStateModel>({
                        store: patch<Stores>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Store updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchStore(id))
            },
            error: (e) => {
                setState(
                    patch<StoreStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding store data', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetStore)
    resetState({ setState }: StateContext<StoreStateModel>
    ) {
        setState(
            patch<StoreStateModel>({
                store: null,
                loader: false
            })
        )
    }

    @Action(FetchStoreTypes)
    fetchStoreTypes({ patchState, setState }: StateContext<StoreStateModel>,
    ) {
        patchState({ loader: true });
        this.apiService.get(`stores/types`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<StoreStateModel>({
                            storeTypes: res,
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<StoreStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching store data', 'close', {
                    duration: 3000
                });
            }
        })
    }

}
