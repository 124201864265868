import { Injectable } from '@angular/core';
import { Auth, User, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { UserDetails } from 'app/shared/store/auth/auth.model';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, of, take } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IpAddressService } from '../ipAddress/ip-address.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { FuseConfigService } from '@fuse/services/config';
import { UserService } from '../user/user.service';


const initialAuthToken: string = "";
const initialAuthState: UserDetails = {
    name: null,
    role: null,
    iss: null,
    aud: null,
    auth_time: null,
    user_id: null,
    sub: null,
    iat: null,
    exp: null,
    email: null,
    email_verified: null,
    firebase: null
}
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _authenticated: boolean = false;
    private isLoading = new BehaviorSubject<boolean>(false);

    private readonly _authState = new BehaviorSubject<UserDetails>(initialAuthState);
    private readonly _authToken = new BehaviorSubject<string>(initialAuthToken);
    private readonly _productionStatCounts = new BehaviorSubject<any>(null);

    readonly isLoading$ = this.isLoading.asObservable();
    readonly auth$ = this._authState.asObservable();
    public readonly authToken$ = this._authToken.asObservable();
    readonly productionStatCounts$ = this._productionStatCounts.asObservable();

    userRole: string;

    constructor(
        private _auth: Auth,
        private _localStorageService: LocalStorageService,
        private ipAddressService: IpAddressService,
        private apiService: ApiService,
        private _fuseConfigService: FuseConfigService,
        private userService: UserService
    ) {
        this._auth.onAuthStateChanged((user: User) => {
            user?.getIdToken(true).then(token => {
                const decodedToken: any = jwtDecode(token);
                this.userRole = decodedToken.role;
                if (decodedToken.role === 'client_admin' || decodedToken.role === 'client_user' || decodedToken.role === 'client_designer') {
                    this.notifyUser().pipe(take(1)).subscribe(
                        {
                            next: (res) => { },
                            error: (error) => {
                                console.error(error);
                            },
                        }
                    )
                    this._fuseConfigService.config = {layout: 'modern'};
                } else {
                    this._fuseConfigService.config = {layout: 'classy'};
                }
            });
            if (!user) {
                this.isLoading.next(false);
                this._authState.next(initialAuthState);
            } else {
                user.getIdToken(true).then(token => {
                    this.isLoading.next(false);
                    this._authState.next(jwtDecode<UserDetails>(token));
                    this._authToken.next(token);
                    const decodedToken: any = jwtDecode(token);
                    this.userRole = decodedToken.role;
                    if(decodedToken.role === 'admin' || decodedToken.role === 'store_manager' || decodedToken.role === 'prepress'){
                        this.userService.getProductionStatCount().pipe(take(1)).subscribe((data) => {
                            if(data){
                                this._productionStatCounts.next(data);
                            }
                        });
                    }
                });
            }
        });
    }

    get authenticated(): boolean {
        return this._authenticated;
    }

    async firebaseSignIn(email: string, password: string): Promise<any> {
        try {
            const userCredential: any = await signInWithEmailAndPassword(this._auth, email, password);
            const token = userCredential?._tokenResponse?.idToken;
            const refreshToken = userCredential?._tokenResponse?.refreshToken;
            if (token) {
                this.decodeAndSetUserToken(token, refreshToken);
                this._authenticated = true;
            }
            //await this.ipAddressService.getUserIp();
        } catch (error) {
            throw error;
        }
    }

    signOut = () => {
        this._localStorageService.clearUser();
        this._authToken.next(null)
        signOut(this._auth);
    }

    refreshToken(): Observable<string> {
        return new Observable<string>((observer) => {
            const currentUser = this._auth.currentUser;
            if (!currentUser) {
                observer.error('Current user is undefined');
            }

            currentUser.getIdToken(true).then((token: string) => {
                observer.next(token);
                this._authToken.next(token);
                observer.complete();
            }).catch((error) => {
                console.error('Error refreshing token:', error);
                observer.error(error);
            });
        });
    }


    private decodeAndSetUserToken(token: string, refreshToken?: string): void {
        const decodedToken: UserDetails = jwtDecode<UserDetails>(token);
        this._localStorageService.setUser(decodedToken);
        this._localStorageService.setToken(token);
        this._localStorageService.setRefreshToken(refreshToken);
    }

    check(): Observable<boolean> {
        if (this._authenticated) {
            return of(true);
        }
        const token = this._localStorageService.getToken();
        if (!token || this.isTokenExpired(token)) {
            return of(false);
        }
        return of(true);
    }

    isTokenExpired(token: string): boolean {
        const currentTime = Math.floor(Date.now() / 1000);
        try {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            return decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding or parsing the token:', error);
            return true;
        }
    }

    forgotPassword(email: string) {
        return this.apiService.patch(`users/resetPassword`, { email });
    }

    async getCurrentUserRole(): Promise<string | null> {
        const user = await this._auth.currentUser;
        if (user) {
            const token = await user.getIdToken();
            const decodedToken: any = jwtDecode(token);
            return decodedToken.role || null;
        }
        return null;
    }

    subscribeToTopic(token: string): Observable<any> {
        return this.apiService.post('users/subscribe/topic/web', { token })
    }

    unsubscribeFromTopic(token: string): Observable<any> {
        return this.apiService.post('users/unsubscribe/topic/web', { token })
    }

    notifyUser() {
        return this.apiService.get(`users/notifyAdmin`);
    }

}
