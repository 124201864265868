import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpParams } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class UserService {


    constructor(private apiService: ApiService,) { }

    async createUser(payload: any) {
        return this.apiService.post('users', payload);
    }

    getFirebaseLog(id: string) {
        return this.apiService.get(`users/firebase_logs/${id}`);
    }

    verifyEmail(email: string) {
        return this.apiService.get(`users/verifyUserEmail`, { email: email });
    }

    enableAndDisableUser(id: string, payload: any) {
        return this.apiService.patch(`users/toggleUserStatus/${id}`, payload)
    }

    deleteUser(id: string) {
        return this.apiService.delete(`users/${id}`)
    }

    getPrepressPaymentTerms(): string[] {
        return ["Weekly", "Bi-weekly", "Monthly", "Per Campaign", "Quarterly"];
    }

    getUsers(page: number, limit: number, id?: string, installerId?: string) {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('limit', limit.toString())
        if (id) {
            params = params.set('client', id);
        }
        if (installerId) {
            params = params.set('installer', installerId);
        }
        return this.apiService.get(`users?${params.toString()}`);
    }

    getProductionStatCount(){
        return this.apiService.get('users/production-stats/count');
    }

    getJobTitles(): string[] {
        return [
            "CEO",
            "COO",
            "CFO",
            "CMO",
            "Owner",
            "Co-Owner",
            "Manager",
            "Marketing Manager",
            "Marketing",
            "Worker",
            "Other"
        ]
    }
}
