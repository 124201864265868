import { User } from "./user.model";

export class AddUser {
    static readonly type = '[User] Add User';
    constructor(public payload: User) { }
}

export class UpdateUser {
    static readonly type = '[User] Update User';
    constructor(public id: string, public payload: User) { }
}

export class FetchUser{
    static readonly type = '[User] Fetch User';
    constructor(public id: string) { }
}

export class FetchUserRole {
    static readonly type = '[User] Fetch User Role';
    constructor() { }
}

export class resetState {
    static readonly type = '[User] Reset User';
    constructor() { }
}
