export class AddAsset {
    static readonly type = '[Asset] Add Asset';
    constructor(public payload: any, public id:string) { }
}

export class UpdateAsset {
    static readonly type = '[Asset] Update Asset';
    constructor(public id: string, public payload: any) { }
}

export class getAssetById {
    static readonly type = '[Asset] Get Asset'
    constructor(public id: string, public skipAuth: boolean = false, public user?: string, public clientId?: string, public assetId?: string, public campaignId?: string){}
}

export class resetAsset {
    static readonly type = '[Asset] Reset Asset';
    constructor() { }
}