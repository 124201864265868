import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { cloneDeep } from 'lodash';
import { Observable, ReplaySubject, skipWhile, take, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private productionStatCounts$: Observable<any>;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    constructor(
        private authService: AuthService
    ){ 
        this.productionStatCounts$ = this.authService.productionStatCounts$;
    }

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) =>
            {
                this._navigation.next(navigation);
                this.productionStatCounts$.pipe(skipWhile(productionStatCounts => !productionStatCounts)).subscribe((data: {
                    awaitingartworkcount: string,
                    prepressuploadcount: string,
                    printordersubmissioncount: string,
                    printorderapprovalcount: string,
                    printproofapprovalcount: string,
                    installerjobscount: string,
                    installerproofapprovalcount: string
                }) => {
                    if(data){
                        let _nav = cloneDeep(navigation);
                        _nav.default.forEach((item) => {
                            if(item.id === 'productionGroup'){
                                
                                if (Object.values(data).some(value => parseInt(value) > 0)) {
                                    item.badge = {
                                        title: '',
                                        classes: 'fuse-vertical-navigation-item-badge-content animate-pulse w-2 h-2 bg-accent-500 font-semibold rounded-full'
                                    };
                                }
                                
                                item.children.forEach((child) => {
                                    if(child.id === 'artworks'){
                                        if(data.awaitingartworkcount && +data.awaitingartworkcount > 0){
                                            child.badge = {
                                                title: data.awaitingartworkcount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'prepressJobs'){
                                        if(data.prepressuploadcount && +data.prepressuploadcount > 0){
                                            child.badge = {
                                                title: data.prepressuploadcount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'onstorePrepressSubmission'){
                                        if(data.printordersubmissioncount && +data.printordersubmissioncount > 0){
                                            child.badge = {
                                                title: data.printordersubmissioncount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'onstorePrepressApproval'){
                                        if(data.printorderapprovalcount && +data.printorderapprovalcount > 0){
                                            child.badge = {
                                                title: data.printorderapprovalcount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'prepressApproval'){
                                        if(data.printproofapprovalcount && +data.printproofapprovalcount > 0){
                                            child.badge = {
                                                title: data.printproofapprovalcount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'installerJobs'){
                                        if(data.installerjobscount && +data.installerjobscount > 0){
                                            child.badge = {
                                                title: data.installerjobscount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } else if(child.id === 'installerProofApproval'){
                                        if(data.installerproofapprovalcount && +data.installerproofapprovalcount > 0){
                                            child.badge = {
                                                title: data.installerproofapprovalcount,
                                                classes: 'fuse-vertical-navigation-item-badge-content w-5 h-5 bg-accent-50 text-accent-500 border border-accent-500 font-semibold rounded-full'
                                            }
                                        }
                                    } 
                                });

                            }
                        });
                        this._navigation.next(_nav);
                        }
                });
            }),
        );
    }
}
