import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private _http: HttpClient
    ) { }

    getWithoutBase(url: string, params?: any): Observable<any>{
        const headers = {
            'Content-Type': 'application/json',
        };

        return this._http.get(url, {
            headers: new HttpHeaders(headers),
            params: params
        })
    }

    get(url: string, params?: any, _headers?: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/json',
        };
        const mergedHeaders = { ...headers, ..._headers };
        
        return this._http.get(environment.api.baseUrl + url, {
            headers: new HttpHeaders(mergedHeaders),
            params: params
        })
    }

    post(url: string, body: any, params?: any, _headers?: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const mergedHeaders = { ...headers, ..._headers };

        return this._http.post(environment.api.baseUrl + url, body, { headers: new HttpHeaders(mergedHeaders), });
    }

    postFormData(url: string, body: any): Observable<any> {
        return this._http.post(environment.api.baseUrl + url, body);
    }

    patch(url: string, body?: any, params?: any, _headers?: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const mergedHeaders = { ...headers, ..._headers };

        return this._http.patch(environment.api.baseUrl + url, body, { headers: new HttpHeaders(mergedHeaders), params: params});
    }

    delete(url: string, body?: any): Observable<any> {


        const headers = {
            'Content-Type': 'application/json'
        };
        return this._http.delete(environment.api.baseUrl + url, {body});

    }
}
