import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Campaign, CampaignAssetsAndStoresStateModel, CampaignStateModel } from './campaign.model';
import { ApiService } from 'app/shared/services/api/api.service';
import { take } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddCampaign, AddStoresAssets, FetchCampaign, FetchCampaignStoresAndAssets, FetchInsertionOrders, FetchPrepressJobs, ResetStoresAndAssets, UpdateCampaign, resetCampaign } from './campaign.actions';
import { cloneDeep } from 'lodash';

@State<CampaignStateModel>({
    name: 'campaign',
    defaults: {
        campaign: null,
        prepress: null,
        loader: false,
        prepressLoader: false,
        insertionOrderLoader: true
    }
})
@Injectable()
export class CampaignState {

    constructor(private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchCampaign)
    FetchCampaigns({ patchState, setState }: StateContext<CampaignStateModel>,
        { id }: FetchCampaign) {
        patchState({ loader: true });
        this.apiService.get(`campaign/${id}`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<CampaignStateModel>({
                            campaign: patch<Campaign>(res),
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching campaigns data.', 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(FetchPrepressJobs)
    FetchPrepressJobs({ patchState, setState, dispatch }: StateContext<CampaignStateModel>,
        { campaignId }: FetchPrepressJobs
    ) {
        patchState({ prepressLoader: true });
        this.apiService.get(`campaign/${campaignId}/assets`).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<CampaignStateModel>({
                        prepress: patch<any>(res),
                        prepressLoader: false
                    })
                )

                dispatch(new FetchInsertionOrders(campaignId))
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        prepressLoader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching prepress jobs.', 'close', {
                    duration: 3000
                });
            }
        });
    }

    @Action(FetchInsertionOrders)
    FetchInsertionOrders({ getState, patchState, setState, dispatch }: StateContext<CampaignStateModel>,
        { campaignId }: FetchInsertionOrders
    ) {
        patchState({ insertionOrderLoader: true });
        this.apiService.get(`campaign/${campaignId}/assets/insertion-orders`).pipe(take(1)).subscribe({
            next: (res: any) => {
                const { prepress } = cloneDeep(getState());
                
                if (res) {
                    prepress.assets = prepress.assets.map((asset: any) => {
                        const updatedAsset = res.assets.find((io: any) => io.id === asset.id);
                        if (updatedAsset) {
                            return { ...asset, insertionOrders: updatedAsset.insertionOrders };
                        }
                        return asset;
                    });
                }
                
                patchState({ prepress, insertionOrderLoader: false });
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        insertionOrderLoader: false
                    })
                )
                this._snackBar.open('Error Occured while fetching prepress jobs.', 'close', {
                    duration: 3000
                });
            }
        });
    }

    @Action(AddCampaign)
    AddCampaign({ patchState, setState }: StateContext<CampaignStateModel>,
        { payload }: AddCampaign
    ) {
        patchState({ loader: true });
        this.apiService.post('campaign', payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<CampaignStateModel>({
                        campaign: patch<Campaign>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Campaign created successfuly.', 'close', {
                    duration: 3000
                });
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding campaign.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(AddStoresAssets)
    AddStoresAssets({ patchState, setState }: StateContext<CampaignStateModel>, { campaignId, storesAssets }: AddStoresAssets
    ) {
        patchState({ loader: true });
        this.apiService.post(`campaign/${campaignId}/add-assets`, storesAssets).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<CampaignStateModel>({
                        campaign: patch<Campaign>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Campaign created successfuly.', 'close', {
                    duration: 3000
                });
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while adding campaign.', 'close', {
                    duration: 3000
                });

            }
        });
    }


    @Action(UpdateCampaign)
    UpdateCampaign({ patchState, setState }: StateContext<CampaignStateModel>,
        { id, payload }: UpdateCampaign
    ) {
        patchState({ loader: true });
        this.apiService.patch(`campaign/${id}`, payload).pipe(take(1)).subscribe({
            next: (res: any) => {
                setState(
                    patch<CampaignStateModel>({
                        campaign: patch<Campaign>(res?.data),
                        loader: false
                    })
                )
                this._snackBar.open('Campaign updated successfuly', 'close', {
                    duration: 3000
                });
                this.store.dispatch(new FetchCampaign(id))
            },
            error: (e) => {
                setState(
                    patch<CampaignStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open('Error Occured while updating campaign data.', 'close', {
                    duration: 3000
                });

            }
        });
    }

    @Action(resetCampaign)
    resetState({ setState }: StateContext<CampaignStateModel>
    ) {
        setState(
            patch<CampaignStateModel>({
                campaign: null,
                loader: false
            })
        )
    }

}

@State<CampaignAssetsAndStoresStateModel>({
    name: 'campaignAssetsAndStores',
    defaults: {
        assets: [],
        loader: false
    }
})
@Injectable()
export class CampaignAssetsAndStoresState {

    constructor(
        private apiService: ApiService,
        private _snackBar: MatSnackBar,
        private store: Store
    ) { }

    @Action(FetchCampaignStoresAndAssets)
    FetchCampaignStoresAndAssets({ patchState, setState }: StateContext<CampaignAssetsAndStoresStateModel>,
        { id }: FetchCampaignStoresAndAssets) {
        patchState({ loader: true });
        this.apiService.get(`campaign/${id}/stores-and-assets`).pipe(take(1)).subscribe({
            next: (res: any) => {
                if (res) {
                    setState(
                        patch<CampaignAssetsAndStoresStateModel>({
                            assets: res,
                            loader: false
                        })
                    )
                }
            },
            error: (e) => {
                setState(
                    patch<CampaignAssetsAndStoresStateModel>({
                        loader: false
                    })
                )
                this._snackBar.open(`Error Occured while fetching campaign's store data.`, 'close', {
                    duration: 3000
                });
            }
        })
    }

    @Action(ResetStoresAndAssets)
    resetState({ setState }: StateContext<CampaignAssetsAndStoresStateModel>
    ) {
        setState(
            patch<CampaignAssetsAndStoresStateModel>({
                assets: [],
                loader: false
            })
        )
    }

}
