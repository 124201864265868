/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { NavigationService } from 'app/shared/services/navigation/navigation.service';
const navigationService = new NavigationService();

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'store',
        title: 'Stores',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/store',
        hidden(item) {
            return !navigationService.hasRole('Stores');
        },
    },
    {
        id: 'store',
        title: 'Map',
        type: 'basic',
        icon: 'feather:map-pin',
        link: '/map',
        hidden(item) {
            return !navigationService.hasRole('Map');
        },
    },
    {
        id: 'communications',
        title: 'Communications',
        type: 'basic',
        icon: 'heroicons_outline:chat-bubble-bottom-center-text',
        link: '/communication',
        hidden(item) {
            return !navigationService.hasRole('Communications');
        },
    },
    {
        type: 'divider',
        hidden(item) {
            return !navigationService.hasRole('campaignGroup');
        },
    },
    {
        id: 'campaignGroup',
        title: 'Campaigns',
        type: 'collapsable',
        icon: 'feather:briefcase',
        hidden(item) {
            return !navigationService.hasRole('campaignGroup');
        },
        children: [
            {
                id: 'proposals',
                title: 'Proposals',
                type: 'basic',
                link: '/proposals',
                hidden(item) {
                    return !navigationService.hasRole('Proposals');
                },
            },
            {
                id: 'campaigns',
                title: 'All Campaigns',
                type: 'basic',
                link: '/campaigns',
                hidden(item) {
                    return !navigationService.hasRole('Campaigns');
                },
            }
        ]
    },
    {
        type: 'divider',
        hidden(item) {
            return !navigationService.hasRole('productionGroup');
        },
    },
    {
        id: 'productionGroup',
        title: 'Production',
        type: 'collapsable',
        icon: 'heroicons_outline:adjustments-horizontal',
        hidden(item) {
            return !navigationService.hasRole('productionGroup');
        },
        children: [
            {
                id: 'artworks',
                title: 'Awaiting Artwork',
                type: 'basic',
                link: '/production/artwork',
                hidden(item) {
                    return !navigationService.hasRole('Awaiting Artwork');
                },
            },
            {
                id: 'prepressJobs',
                title: 'Prepress Upload',
                type: 'basic',
                link: '/production/prepress-jobs',
                hidden(item) {
                    return !navigationService.hasRole('Awaiting Prepress');
                },
            },
            {
                id: 'onstorePrepressApproval',
                title: 'Print Order Approval',
                type: 'basic',
                link: '/production/onstore-prepress-approval',
                hidden(item) {
                    return !navigationService.hasRole('Awaiting Prepress Approval');
                },
            },
            {
                id: 'onstorePrepressSubmission',
                title: 'Print Order Submission',
                type: 'basic',
                link: '/production/print-order-submission',
                hidden(item) {
                    return !navigationService.hasRole('Print Order Submission');
                },
            },
            {
                id: 'prepressApproval',
                title: 'Print Proof Approval',
                type: 'basic',
                link: '/production/printing-approval',
                hidden(item) {
                    return !navigationService.hasRole('Awaiting Printing Approval');
                },
            },
            {
                id: 'installerJobs',
                title: 'Installer Jobs',
                type: 'basic',
                link: '/production/installer-jobs',
                hidden(item) {
                    return !navigationService.hasRole('Installer Jobs');
                },
            },
            {
                id: 'removalJobs',
                title: 'Removal Jobs',
                type: 'basic',
                link: '/production/removal-jobs',
                hidden(item) {
                    return !navigationService.hasRole('Removal Jobs');
                },
            },
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'AccountPayableGroup',
        title: 'Accounts Payable',
        type: 'collapsable',
        icon: 'heroicons_outline:currency-dollar',
        hidden(item) {
            return !navigationService.hasRole('AccountPayables');
        },
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'basic',
                link: '/account-payable/overview',
                hidden(item) {
                    return !navigationService.hasRole('Overview');
                },
            },
            {
                id: 'Store/Vendor',
                title: 'Store Vendor Payables',
                type: 'basic',
                link: '/account-payable/vendors',
                hidden(item) {
                    return !navigationService.hasRole('Store/Vendor');
                },
            },
            // {
            //     id: 'Printer',
            //     title: 'Printer',
            //     type: 'basic',
            //     link: '/account-payable/printers',
            //     hidden(item) {
            //         return !navigationService.hasRole('Printer');
            //     },
            // },
            // {
            //     id: 'Installer/Remover',
            //     title: 'Installer/Remover',
            //     type: 'basic',
            //     link: '/account-payable/installers',
            //     hidden(item) {
            //         return !navigationService.hasRole('Installer/Remover');
            //     },
            // },
            // {
            //     id: 'Prepress',
            //     title: 'Prepress',
            //     type: 'basic',
            //     link: '/account-payable/prepress',
            //     hidden(item) {
            //         return !navigationService.hasRole('Prepress');
            //     },
            // },
        ]
    },
    {
        type: 'divider'
    },
    {
        id: 'profileGroup',
        title: 'Profiles',
        type: 'group',
        hidden(item) {
            return !navigationService.hasRole('profileGroup');
        },
    },
    {
        id: 'products',
        title: 'Products',
        type: 'basic',
        icon: 'heroicons_outline:swatch',
        link: '/products',
        hidden(item) {
            return !navigationService.hasRole('Products');
        },
    },
    {
        id: 'clients',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/client',
        hidden(item) {
            return !navigationService.hasRole('Clients');
        },
    },
    {
        id: 'installersRemovers',
        title: 'Installers/Removers',
        icon: 'heroicons_outline:document',
        type: 'basic',
        link: '/installer',
        hidden(item) {
            return !navigationService.hasRole('Installers/Removers');
        },
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/user',
        hidden(item) {
            return !navigationService.hasRole('Users');
        },
    },
    // {
    //     id: 'contracts',
    //     title: 'Contracts',
    //     type: 'basic',
    //     link: '/under-development',
    //     hidden(item) {
    //         return !navigationService.hasRole('Contracts');
    //     },
    // },
    // {
    //     id: 'production',
    //     title: 'Production',
    //     type: 'collapsable',
    //     link: '/under-development',
    //     hidden(item) {
    //         return !navigationService.hasRole('Production');
    //     },
    //     children: [
    //         {
    //             id: 'prepress',
    //             title: 'Prepress',
    //             type: 'collapsable',
    //             link: '/under-development',
    //             children: [
    //                 {
    //                     id: 'artWorkDatabase',
    //                     title: 'Artwork Database',
    //                     type: 'basic',
    //                     link: '/production/artwork',
    //                 },
    //                 {
    //                     id: 'prePressJobs',
    //                     title: 'Prepress Jobs',
    //                     type: 'basic',
    //                     link: '/production/prepress-jobs',
    //                 },
    //                 {
    //                     id: 'prePressApproval',
    //                     title: 'Prepress Approval',
    //                     type: 'basic',
    //                     link: '/production/prepress-approval',
    //                 },
    //             ]

    //         },
    //         {
    //             id: 'print',
    //             title: 'Print',
    //             type: 'basic',
    //             link: '/under-development'
    //         },
    //         {
    //             id: 'install',
    //             title: 'Install',
    //             type: 'basic',
    //             link: '/under-development'
    //         },
    //         {
    //             id: 'remove',
    //             title: 'Remove',
    //             type: 'basic',
    //             link: '/under-development'
    //         },
    //     ],
    // },
    // {
    //     id: 'accountsPayable',
    //     title: 'Accounts Payable',
    //     type: 'basic',
    //     link: '/account-payable',
    //     hidden(item) {
    //         return !navigationService.hasRole('Accounts Payable');
    //     }
    // },
    // {
    //     id: 'accountsReceiveable',
    //     title: 'Accounts Receivable',
    //     type: 'basic',
    //     link: '/account-receivable',
    //     hidden(item) {
    //         return !navigationService.hasRole('Accounts Receivable');
    //     },
    // },

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        classes: {
            icon: 'text-white',
            title: 'text-white'
        },
        link: '/analytics',
        hidden(item) {
            return !navigationService.hasRole('Client Map')
        }
    },
    {
        id: 'store',
        title: 'Map',
        type: 'basic',
        icon: 'feather:map-pin',
        classes: {
            icon: 'text-white',
            title: 'text-white'
        },
        link: '/map-view',
        hidden(item) {
            return !navigationService.hasRole('Client Map');
        },
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user',
        classes: {
            icon: 'text-white',
            title: 'text-white focused:text-primary'
        },
        link: '/user',
        hidden(item) {
            return !navigationService.hasRole('Client Users')
        }
    },
    {
        id: 'artwork',
        title: 'Artwork',
        type: 'basic',
        icon: 'heroicons_outline:photo',
        link: '/production/artwork',
        classes: {
            icon: 'text-white',
            title: 'text-white focused:text-primary'
        },
        hidden(item) {
            return !navigationService.hasRole('User Artwork')
        }
    },
    {
        id: 'agreement',
        title: 'Agreements',
        type: 'basic',
        icon: 'heroicons_outline:document-check',
        link: 'agreements',
        classes: {
            icon: 'text-white',
            title: 'text-white focused:text-primary'
        },
        hidden(item) {
            return !navigationService.hasRole('User Agreement')
        }
    },
];
